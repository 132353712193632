import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePrevious } from "./usePrevious";
import Authentication from "../modules/authentication";
import { setManagedIndexes } from "../store/indexes";
import { setManagedExchanges } from "../store/exchanges";
import {
    setDividendTaxCountries,
    setManagedSecurities,
    setManagedSources,
    setCentralBanks,
} from "../store/securities";
import { DateTime } from "luxon";
import { setReportClients, setReportTypes } from "../store/reports";
import { setBackendDetails } from "../store/user";

export const API_DATE_FORMAT = "yyyy-MM-dd";

// How many trading days to display for each exchange (starting yesterday)
const TRADING_DAYS_COUNT = 6;

const gApiClient = Authentication.getAPIClient();

// What APIs to call to load data, and what Redux reducer functions to call for each one (to store the results
// of those APIs into the Redux store)
const DATA_URLS = {
    managed_indexes: { cb: setManagedIndexes },
    managed_exchanges: {
        cb: setManagedExchanges,
        params: {
            // This API returns active trade dates for each exchange, relative to today, so we need to tell
            // the server the local date (in case of different timezones - the server works in GMT+0)
            today: DateTime.local().toFormat(API_DATE_FORMAT),
            // How many trading days to return for each exchange (starting yesterday)
            trading_days: TRADING_DAYS_COUNT,
        },
    },
    managed_securities: {
        cb: setManagedSecurities,
        loader: async (url) => {
            // We only need a few columns - this data is used throughout the website just for display purposes - e.g.
            // when showing a CA's security. We do this since otherwise, returning thousands of securities with
            // dozens of columns each, will be a very large response size, also requiring us to split it up
            // into multiple API requests.
            const response = await gApiClient.callApi(url, {
                method: "GET",
                params: {
                    page: 1,
                    per_page: 200000,
                    columns: [
                        "internal_id",
                        "name",
                        "ric",
                        "isin",
                        "currency",
                        "domicile_country_code",
                        "exchange_id",
                    ],
                },
            });
            return response.data.results;
        },
    },
    managed_sources: { cb: setManagedSources },
    dividend_tax_countries: { cb: setDividendTaxCountries },
    report_types: { cb: setReportTypes },
    report_clients: { cb: setReportClients },
    central_banks: { cb: setCentralBanks },
    backend: { cb: setBackendDetails },
};

/**
 * Custom hook that loads all IRIS-related data (e.g. managed indexes, securities).
 * All data is loaded only once, when user login state changes to logged-in.
 * All data is saved into Redux store.
 *
 * @returns true if data is being loaded at the moment, false otherwise
 */
export const useLoadAllData = () => {
    const dispatch = useDispatch();
    const loginState = useSelector((state) => state.user.loginState);
    const previousLoginState = usePrevious(loginState);
    const [loading, setLoading] = useState(false);

    const loadAllData = useCallback(async () => {
        setLoading(true);

        // Call all APIs to load IRIS-related data
        const requests = Object.keys(DATA_URLS).map(async (u) => {
            let data;
            if (DATA_URLS[u].loader) {
                // Use custom loader
                data = await DATA_URLS[u].loader(u);
            } else {
                const response = await gApiClient.callApi(u, {
                    method: "GET",
                    params: DATA_URLS[u].params || {},
                    body: {},
                });
                data = response.data;
            }
            // Save response to Redux store (to be accessible by all pages of the website)
            dispatch(DATA_URLS[u].cb(data));
        });
        // Wait for all API calls to finish
        await Promise.all(requests);

        setLoading(false);
    }, [dispatch]);

    useEffect(() => {
        if (
            previousLoginState !== loginState &&
            loginState === Authentication.LOGIN_STATUS_LOGGED_IN
        ) {
            // User logged-in for the first time - load all initial data needed by IRIS
            loadAllData();
        }
    }, [loginState, loadAllData, previousLoginState]);

    return loading;
};
