import * as React from "react";
import Title from "../components/Title";
import { Button, CircularProgress, TextField } from "@mui/material";
import { useState } from "react";
import Authentication from "../modules/authentication";
import ErrorLabel from "../components/Error";
import { Link } from "react-router-dom";

export default function Register() {
    const [loading, setLoading] = useState(false);
    const [waitForConfirmation, setWaitForConfirmation] = useState(false);
    const [error, setError] = useState(null);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const verifyForm = email && password;

    const register = () => {
        setLoading(true);
        setError(null);

        // Login with username/password combo
        Authentication.registerUser(
            email.toLowerCase().trim(),
            email.toLowerCase().trim(),
            password,
            () => {
                setLoading(false);
                setWaitForConfirmation(true);
            },
            (err) => {
                // Couldn't register
                setLoading(false);
                console.error(err);
                setError(err.message);
            },
        );
    };

    return (
        <div className="w-96 flex flex-col">
            <Title>Register</Title>
            {waitForConfirmation ? (
                <div className="text-4xl mt-10 text-green-800">
                    Registration successful - please contact Index with your
                    email address to get your user confirmed.
                </div>
            ) : (
                <>
                    <TextField
                        id="standard-basic"
                        label="Email"
                        variant="standard"
                        autoComplete="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />

                    <TextField
                        className="mt-10"
                        id="standard-basic"
                        label="Password"
                        variant="standard"
                        type="password"
                        value={password}
                        onKeyPress={(e) => {
                            if (e.key === "Enter" && verifyForm) register();
                        }}
                        onChange={(e) => setPassword(e.target.value)}
                    />

                    <Button
                        className="w-48 mt-10"
                        variant="contained"
                        onClick={register}
                        disabled={!verifyForm || loading}
                    >
                        {loading ? (
                            <CircularProgress color="secondary" size={25} />
                        ) : (
                            "Register"
                        )}
                    </Button>
                    {error && <ErrorLabel>{error}</ErrorLabel>}

                    <Link to="/login" className="mt-5 font-bold">
                        Login instead?
                    </Link>
                </>
            )}
        </div>
    );
}
