import * as React from "react";
import Title from "../components/Title";

export default function NotVerified() {
    return (
        <>
            <Title>Not Verified</Title>
            <div className="text-4xl mt-10 text-green-800">
                Please contact Index with your email address to get your user
                confirmed, so you can access IRIS.
            </div>
        </>
    );
}
