/**
 * Contains any methods related to user authentication (against the IRIS APIs) -
 * login, registration, etc. Uses AWS Cognito and API Gateway libraries.
 */
import axios from "axios";
import queryString from "query-string";
import { getWebsiteStage } from "./stages";

const AWSCognito = require("amazon-cognito-identity-js");
const apigClientFactory = require("aws-api-gateway-client");

const STAGE_PROD = "prod";
const STAGE_DEV = "dev";
const STAGE_RC = "rc";

const Config = {
    appClientId: "1vlod6d8eqck9r6djn0saqa4q9",
    userPoolId: "us-east-1_dTJUUPO19",
    awsRegion: "us-east-1",
    apiBase: {
        [STAGE_DEV]: "https://api-dev.indx.co.il",
        [STAGE_RC]: "https://api-rc.indx.co.il",
        [STAGE_PROD]: "https://api.indx.co.il",
    },
};

// Configure the AWS Cognito client
const gUserPool = new AWSCognito.CognitoUserPool({
    UserPoolId: Config.userPoolId,
    ClientId: Config.appClientId,
});

const STORAGE_KEY_PREFIX = "iris_";
const STORAGE_KEY_USER_DETAILS = `${STORAGE_KEY_PREFIX}user_details`;

/** Get the saved access token from the session - refreshes the token if needed.
 * Callback function receives accessToken and error instances */
function getAccessToken(callback) {
    const user = gUserPool.getCurrentUser();

    if (user == null) {
        callback(null, "User not logged in");
        return;
    }

    user.getSession((err, session) => {
        if (err) {
            callback(null, err);
            return;
        }

        if (session.isValid()) {
            // Return the token
            callback(session.getIdToken().getJwtToken(), null);
            return;
        }

        // Need to refresh token
        user.refreshSession(session.getRefreshToken(), (error, newSession) => {
            if (error) {
                callback(null, error);
                return;
            }

            // Return the new token
            callback(newSession.getIdToken().getJwtToken(), null);
        });
    });
}

export default class Authentication {
    // The various login states
    static LOGIN_STATUS_NOT_LOGGED_IN = "not_logged_in";
    static LOGIN_STATUS_LOGGED_IN = "logged_in";
    static LOGIN_STATUS_UNCONFIRMED = "unconfirmed";

    static userDetails = null;

    static getUserDetails() {
        const json = localStorage.getItem(STORAGE_KEY_USER_DETAILS);

        if (json === null) {
            return null;
        }

        return JSON.parse(json);
    }

    static setUserDetails(userDetails) {
        localStorage.setItem(
            STORAGE_KEY_USER_DETAILS,
            JSON.stringify(userDetails),
        );
    }

    static removeUserDetails() {
        localStorage.removeItem(STORAGE_KEY_USER_DETAILS);
    }

    /** Logs the user out */
    static logOut() {
        const cognitoUser = gUserPool.getCurrentUser();

        if (!cognitoUser) {
            return;
        }

        cognitoUser.signOut();
        this.userDetails = null;
        this.removeUserDetails();
    }

    /** Retrieves the logged-in user attributes (email, etc.) */
    static getUserAttributes(callback) {
        const cognitoUser = gUserPool.getCurrentUser();

        if (!cognitoUser) {
            callback("User not logged in", null);
            return;
        }

        cognitoUser.getSession((err, session) => {
            if (err || !session) {
                callback(err, null);
                return;
            }

            cognitoUser.getUserAttributes(callback);
        });
    }

    /** Re-downloads latest user details */
    static refreshUserDetails(callback) {
        const apiClient = this.getAPIClient();
        apiClient
            .callApi("get_logged_in_user", {
                method: "GET",
                params: {},
                body: {},
            })
            .then((response) => {
                console.log("refreshUserDetails - Got user ", response.data);
                this.userDetails = response.data;
                this.setUserDetails(this.userDetails);

                callback(this.userDetails);
            })
            .catch((result) => {
                console.log("error", result);
                callback(null);
            });
    }

    /** Checks whether the user is logged in -
     * returns LOGIN_STATUS_* result via callback param */
    static getLoginStatus(callback) {
        const cognitoUser = gUserPool.getCurrentUser();

        this.userDetails = this.getUserDetails();
        console.log("getLoginStatus", cognitoUser, this.userDetails);

        if (!cognitoUser) {
            callback(this.LOGIN_STATUS_NOT_LOGGED_IN);
            return;
        }

        // Make sure user has confirmed his email

        if (this.userDetails === null) {
            // Refresh user details (to get login status and make sure he's verified)
            this.refreshUserDetails((userDetails) => {
                this.getLoginStatus(callback);
            });
        } else if (!this.userDetails.is_verified) {
            // User hasn't been verified yet by an admin
            callback(this.LOGIN_STATUS_UNCONFIRMED);
        } else {
            callback(this.LOGIN_STATUS_LOGGED_IN);
        }
    }

    static getBaseUrl() {
        const stage = getWebsiteStage();
        return Config.apiBase[stage];
    }

    /** Returns an AWS API Gateway client, configured to perform API
     * calls with the appropriate access token */
    static getAPIClient() {
        const apigClient = apigClientFactory.default.newClient({
            invokeUrl: this.getBaseUrl(),
        });

        // Add a new method for the API client, that injects the authorization header
        // with the most up-to-date access token, before calling the API.
        apigClient.callApi = (
            path,
            { method, params, body, headers, queryParams },
        ) => {
            const promise = new Promise((resolve, reject) => {
                getAccessToken((accessToken, error) => {
                    if (error || !accessToken) {
                        // Couldn't get access token
                        reject(error);
                        return;
                    }

                    const additionalParams = {
                        headers: { authorization: accessToken },
                    };

                    if (headers) {
                        additionalParams.headers = {
                            ...additionalParams.headers,
                            ...headers,
                        };
                    }

                    let finalPath = `/${path}`;

                    if (
                        (method === "GET" || queryParams) &&
                        params &&
                        Object.keys(params).length > 0
                    ) {
                        // Convert into a URL query string
                        const urlParams = queryString.stringify(params, {
                            arrayFormat: "none",
                        });
                        finalPath = `${finalPath}?${urlParams}`;
                    }

                    if (body instanceof ArrayBuffer) {
                        // Special case where HTTP request body is raw bytes - the API gateway library
                        // doesn't know how to handle this (so we'll use axios directly)
                        axios
                            .request({
                                url: `${this.getBaseUrl()}${finalPath}`,
                                method: method.toLowerCase(),
                                data: body,
                                headers: {
                                    ...additionalParams.headers,
                                    "content-type": "application/octet-stream",
                                },
                            })
                            .then(resolve)
                            .catch(reject);
                    } else {
                        apigClient
                            .invokeApi(
                                null,
                                finalPath,
                                method,
                                additionalParams,
                                body,
                            )
                            .then(resolve)
                            .catch(reject);
                    }
                });
            });

            return promise;
        };

        apigClient.callApiUnauthenticated = (
            path,
            { method, params, body },
        ) => {
            const promise = new Promise((resolve, reject) => {
                const finalPath = `${Authentication.getBaseUrl()}/${path}`;

                apigClient
                    .invokeApi(params, finalPath, method, {}, body)
                    .then((data) => {
                        resolve(data);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });

            return promise;
        };

        return apigClient;
    }

    /** Confirms a specific user with a code.  */
    static confirmUsername(username, code, onSuccess, onFailure) {
        const cognitoUser = new AWSCognito.CognitoUser({
            Username: username,
            Pool: gUserPool,
        });

        cognitoUser.confirmRegistration(code, true, (err) => {
            if (err) {
                if (onFailure) onFailure(err);
                return;
            }

            // We'll need to sign out the user - since it appears after confirmation
            // the access token is not fresh and there is no way of re-login without
            // entering username/password combo again.
            cognitoUser.signOut();

            if (onSuccess) onSuccess();
        });
    }

    /** Sends a reset password link to the specified email address. */
    static resetPassword(username, onSuccess, onFailure) {
        const userData = {
            Username: username,
            Pool: gUserPool,
        };

        const cognitoUser = new AWSCognito.CognitoUser(userData);
        cognitoUser.forgotPassword({
            onSuccess,
            onFailure,
        });
    }

    /** Changes a user's password (using old password). */
    static changePassword(
        username,
        oldPassword,
        newPassword,
        onSuccess,
        onFailure,
    ) {
        const changePasswordInner = (user) => {
            user.changePassword(oldPassword, newPassword, (err, result) => {
                if (err) {
                    onFailure(err);
                } else {
                    onSuccess(result);
                }
            });
        };

        const cognitoUser = gUserPool.getCurrentUser();

        cognitoUser.getSession((err, session) => {
            if (err) {
                onFailure(err);
                return;
            }

            if (session.isValid()) {
                // Return the token
                changePasswordInner(cognitoUser);
                return;
            }

            // Need to refresh session
            cognitoUser.refreshSession(
                session.getRefreshToken(),
                (error, newSession) => {
                    if (error) {
                        onFailure(error);
                        return;
                    }

                    // Return the new token
                    changePasswordInner(cognitoUser);
                },
            );
        });
    }

    /** Changes a user's password (using the provided verification code). */
    static confirmPassword(
        username,
        verificationCode,
        newPassword,
        onSuccess,
        onFailure,
    ) {
        const userData = {
            Username: username,
            Pool: gUserPool,
        };

        const cognitoUser = new AWSCognito.CognitoUser(userData);

        cognitoUser.confirmPassword(verificationCode, newPassword, {
            onSuccess,
            onFailure,
        });
    }

    /** Registers a user with a username/email/password combo. */
    static registerUser(username, email, password, onSuccess, onFailure) {
        const attributeList = [];

        const attributeEmail = new AWSCognito.CognitoUserAttribute({
            Name: "email",
            Value: email,
        });

        attributeList.push(attributeEmail);

        gUserPool.signUp(
            username,
            password,
            attributeList,
            null,
            (err, result) => {
                if (err) {
                    console.log("error", err);
                    if (onFailure) onFailure(err);
                    return;
                }

                const user = result.user;
                if (onSuccess) onSuccess(user);
            },
        );
    }

    /** Authenticates the user with a username/password combo. */
    static loginWithPassword(
        username,
        password,
        onSuccess,
        onFailure,
        onNewPasswordRequired,
    ) {
        const authenticationData = {
            Username: username,
            Password: password,
        };

        const userData = {
            Username: username,
            Pool: gUserPool,
        };

        const authenticationDetails = new AWSCognito.AuthenticationDetails(
            authenticationData,
        );
        const cognitoUser = new AWSCognito.CognitoUser(userData);

        // Authenticate the user
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess() {
                if (onSuccess) onSuccess();
            },
            onFailure(err) {
                if (onFailure) onFailure(err);
            },
            newPasswordRequired(userAttributes) {
                console.log("newPasswordRequired", userAttributes);
                // User was signed up by an admin and must provide new
                // password and required attributes, if any, to complete authentication.

                // The api doesn't accept this field back
                const newUserAttributes = userAttributes;
                delete newUserAttributes.email_verified;

                if (onNewPasswordRequired) {
                    const newPassword =
                        onNewPasswordRequired(newUserAttributes);

                    // Change password
                    cognitoUser.completeNewPasswordChallenge(
                        newPassword,
                        userAttributes,
                        this,
                    );
                }
            },
        });
    }
}
