import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    managedExchanges: [],
    exchangesById: {},
};

export const exchangesSlice = createSlice({
    name: "exchanges",
    initialState,
    reducers: {
        setManagedExchanges: (state, action) => {
            state.managedExchanges = action.payload;
            state.exchangesById = Object.fromEntries(
                action.payload.map((e) => [e._id, e]),
            );
        },
    },
});

/** Returns a display name for the exchange (since some exchanges have alternative names) */
export const getExchangeName = (exchange) => {
    return [exchange.name, ...exchange.alternative_names].join(" | ");
};

export const { setManagedExchanges } = exchangesSlice.actions;
export default exchangesSlice.reducer;
