/**
 * Contains any methods related to staging environments.
 */

import packageJson from "../../package.json";

export const STAGE_PROD = "prod";
export const STAGE_RC = "rc";
export const STAGE_DEV = "dev";

// Used to detect current website stage
export const WEBSITE_URLS_BY_STAGE = {
    [STAGE_PROD]: ["iris.indxresearch.com"],
    [STAGE_RC]: ["iris-rc.indxresearch.com"],
    [STAGE_DEV]: ["iris-dev.indxresearch.com", "localhost"],
};

/**
 * Returns the current staging environment of the website. This is done by looking
 * at the current URL host name.
 */
export function getWebsiteStage() {
    const currentHostname = window.location.hostname;

    for (const [stage, urls] of Object.entries(WEBSITE_URLS_BY_STAGE)) {
        if (urls.includes(currentHostname)) {
            return stage;
        }
    }

    // Default stage
    return STAGE_PROD;
}

/**
 * Returns website version. This is done by looking at the "version" field of package.json.
 */
export function getWebsiteVersion() {
    return packageJson.version;
}
