import { createSlice } from "@reduxjs/toolkit";
import Authentication from "../modules/authentication";

const initialState = {
    loginState: Authentication.LOGIN_STATUS_NOT_LOGGED_IN,
    userDetails: null,
    backendDetails: null,
};

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setLoginState: (state, action) => {
            state.loginState = action.payload;
        },
        setUserDetails: (state, action) => {
            state.userDetails = action.payload;
        },
        setBackendDetails: (state, action) => {
            state.backendDetails = action.payload;
        },
    },
});

export const { setLoginState, setUserDetails, setBackendDetails } =
    userSlice.actions;
export default userSlice.reducer;
